import Layout from "../components/layout"
import React from "react";
import '../resources/styles/pages/_contact.scss';
import Arrow from "../components/arrow";
import {graphql} from "gatsby";
import CodicateImage from "../components/codicate-image";
import ContactForm from "../components/contact-form";

const CustomerSuccess = (props) => {

    return (
        <div {...props}>
            <CodicateImage img={props.image} style={{overflow: 'visible', width: '100vw', maxWidth: '500px'}}/>
            <div className={'yellow-bubble'}>
                <h3>Alex Dumoulin</h3>
                <span>Customer Success</span>
            </div>
        </div>
    );
};
let formSection = React.createRef();
const Contact = (props) => {
    return (
        <Layout hasGrisSquares={true} className="page-contact invertLogo whiteBg forceWhiteMenu" isIncludeFooter={false}
            yoast={props.data.wordpressPage.yoast || null}
            pageTitle={props.data.wordpressPage.title}
            seoTitle={props.data.wordpressPage.acf.seoTitle}
        >
            <div className="contact">
                <div className={'contact-content'}>
                    <div className={'catch-phrase'}>
                        <h1>
                            The Longest Journey Starts with a Single Step
                            <Arrow onClick={()=> formSection.current.scrollIntoView({behavior: "smooth"})} style={{fill: 'black'}}/>
                        </h1>
                        <CustomerSuccess className={'image mobile'} image={props.data.alex} />
                    </div>
                    <CustomerSuccess className={'image desktop'} image={props.data.alex} />
                    <div className={'form'} ref={formSection}>
                        <ContactForm
                            SubmitButton={() => <button>SEND</button>}
                            path={props.location.pathname}
                            showContactInfo={true}
                            leftAlignButton={false}
                            whiteIcons={true}
                            formName="contact"
                        />
                    </div>
                </div>
            </div>
            <div className={'half-blue'} />
        </Layout>
    );
};

export default Contact;


export const query = graphql`
        query {
            alex: file(relativePath: { eq: "contact/alex.png"}) {
                childImageSharp {
                    fluid(maxWidth: 600, quality: 90){
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            wordpressPage(template: {eq: "contact.php"}){
                title
                path
                ...YoastData
                acf {
                    seoTitle: seo_title
                }
            }
        }
    `;
